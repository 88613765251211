import 'bootstrap/js/dist/collapse'
import 'bootstrap/dist/css/bootstrap.css'
import { CookieMessage } from './src/common/cookie-message'
import { FavoritesContextProvider } from './src/common/contexts/favoritesContext'
import { AreaContextProvider } from './src/common/contexts/areaContext'
if (typeof window !== 'undefined') {
  require('bootstrap/js/dist/carousel')
}

const React = require('react')

export const wrapPageElement = ({ element, location }) => {
  return (
    <AreaContextProvider>
      <FavoritesContextProvider location={location}>
        <CookieMessage>{element}</CookieMessage>
      </FavoritesContextProvider>
    </AreaContextProvider>
  )
}
