module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[],"enabled":false,"toolbar":{"buttons":{"save":"Speichern","reset":"Änderungen zurücksetzen","exit":"CMS beenden"}},"sidebar":{"buttons":{"save":"Speichern","reset":"Änderungen zurücksetzen"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Alexis Marie"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://wwwstat.uke.de","siteUrl":"https://wwwstat.uke.de/matomo/matomo.php?idsite=3&rec=1","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","exclude":["/offline-plugin-app-shell-fallback/"],"requireConsent":false,"requireCookieConsent":false,"disableCookies":true,"dev":false,"enableJSErrorTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
